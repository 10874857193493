<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-0">
                {{ "Đăng nhập" }}
              </h1>
            </div>
          </template>

          <v-card-text>
            <pages-btn
              large
              color="green"
              depressed
              @click="getMicrosoftLoginPage"
              :loading="loading"
              class=" mt-3"
              >Đăng nhập với tài khoản Microsoft
            </pages-btn></v-card-text
          >
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PagesLogin",

  components: {
    PagesBtn: () => import("@/layouts/pages/components/Btn")
  },

  data: () => ({
    valid: true,
    show: false,
    tab: 0,
    loading: false,
    form: {
      email: "",
      password: ""
    },
    examination: undefined,
    message: undefined,
    rule: {
      nameRules: [v => !!v || "Bạn chưa nhập tên đăng nhập"],
      passwordRules: [v => !!v || "Bạn chưa nhập password"]
    },
    socials: [
      {
        href: "#",
        icon: "mdi-facebook"
      },
      {
        href: "#",
        icon: "mdi-twitter"
      },
      {
        href: "#",
        icon: "mdi-github"
      }
    ]
  }),
  created() {
    if (this.$store.state.user.auth) {
      window.location.href = "/admin/supervisors/monitoring";
    }
  },
  methods: {
    getMicrosoftLoginPage() {
      const host =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3333/msal/"
          : "/msal/";
      const param = "supervisor";
      window.location.href = host + param;
    }
  }
};
</script>
